import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { Case, CaseImageRequest, ImageType, ImageTypeConverter } from '../../../api/caseApiTypes';
import { CaseFormSection } from './CaseFormSection';
import { Check, Delete, EmojiPeople, ExpandLess, ExpandMore, Pets, Tv } from '@mui/icons-material';
import styles from './CaseImageRequestForm.module.css';
import { useAppDispatch } from '../../../hooks/useAppRedux';
import { addCaseImageRequest, removeCaseImageRequest, updateCaseImageRequest } from '../caseDetailSlice';
import { CaseTextField } from '../formFieldComponents/CaseTextField';
import React from 'react';
import { DropResult, ResponderProvided, Draggable, DragDropContext } from 'react-beautiful-dnd';
import { StrictModeDroppable } from '../../kanban/CaseKanbanBoard';

const CaseImageRequestImageTypes = [ImageType.ContextualPhoto, ImageType.InstallationPhoto, ImageType.Detail, ImageType.ProductContextual];

type CompleteImageRequestButtonProps = {
  completed: boolean;
  requestNumber: number;
  toggleRequestComplete: () => void;
};

function ImageRequestCompleteButton({ completed, requestNumber, toggleRequestComplete }: CompleteImageRequestButtonProps) {
  const [showCheckmark, setShowCheckmark] = React.useState(false);
  return (
    <Tooltip title={completed ? 'Återställ' : 'Markera som klar'} placement="left" arrow enterDelay={500} leaveDelay={200}>
      <IconButton
        className={styles.imageRequestId}
        onMouseOver={_ => setShowCheckmark(true)}
        onMouseOut={_ => setShowCheckmark(false)}
        onClick={e => {
          e.stopPropagation();
          toggleRequestComplete();
        }}
      >
        {showCheckmark || completed ? <Check /> : requestNumber}
      </IconButton>
    </Tooltip>
  );
}

type CaseImageRequestAspectStatusTextProps = {
  name: string;
  checked: boolean;
  extraText?: string;
};

function ImageRequestAspectStatusText({ name, checked, extraText }: CaseImageRequestAspectStatusTextProps) {
  if (!checked) return <></>;
  const text = (
    <Typography variant="caption" sx={{ ml: '0.5rem' }}>
      {name}
    </Typography>
  );
  if (extraText) return <Tooltip title={extraText}>{text}</Tooltip>;
  return text;
}

type CaseImageRequestCheckBoxProps = {
  name: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
};

function ImageRequestCheckBox({ name, checked, onChange }: CaseImageRequestCheckBoxProps) {
  return (
    <FormControlLabel
      label={
        <Typography variant="caption" sx={{ mr: '-0.5rem' }}>
          {name}
        </Typography>
      }
      labelPlacement="start"
      control={<Checkbox checked={checked} onChange={e => onChange(e.currentTarget.checked)} />}
    />
  );
}

type CaseImageRequestDimensionProps = {
  name: string;
  checked: boolean;
  dimensions?: string;
  isReadonly?: boolean;
  onChange: (checked: boolean, dimensions?: string) => void;
};

function CaseImageRequestDimension({ name, checked, dimensions, isReadonly, onChange }: CaseImageRequestDimensionProps) {
  dimensions ||= '';
  return (
    <>
      <ImageRequestCheckBox name={name} checked={checked} onChange={checked => onChange(checked, dimensions)} />
      {checked && (
        <CaseTextField
          variant="standard"
          value={dimensions}
          placeholder={'Bildmått (BxH)'}
          setValue={e => onChange(checked, e)}
          inputProps={{ style: { fontSize: '0.75rem', width: '6rem' } }}
          sx={{ marginLeft: '0.5rem' }}
          readonly={isReadonly}
        />
      )}
    </>
  );
}

function isFreshImageRequest(request: CaseImageRequest) {
  return (
    !request.completed &&
    !request.imageType &&
    !request.takePortrait &&
    !request.takeLandscape &&
    !request.takeSquare &&
    !request.hasModel &&
    !request.hasPet &&
    !request.forTv &&
    !request.comments
  );
}

type CaseImageRequestProps = {
  index: number;
  request: CaseImageRequest;
  updateRequest: (request: Partial<CaseImageRequest>) => void;
  deleteRequest: () => void;
};

function CaseImageRequestEntryForm({ index, request, updateRequest, deleteRequest }: CaseImageRequestProps) {
  const [expanded, setExpanded] = React.useState(isFreshImageRequest(request));
  const toggleComplete = () => {
    if (!request.completed) {
      setExpanded(false);
    }
    updateRequest({ completed: !request.completed });
  };
  const hasImageTypeError = !request.imageType;

  const showLandscapeDimensions = request.imageType !== ImageType.ProductContextual;
  const showSquareDimensions = request.imageType !== ImageType.ProductContextual;

  const portraitValues = {
    isReadonly: request.imageType === ImageType.ProductContextual,
    checked: request.imageType === ImageType.ProductContextual ? true : request.takePortrait,
    dimensions: request.imageType === ImageType.ProductContextual ? '360x450' : request.portraitDimensions
  };

  return (
    <Accordion
      variant="outlined"
      disableGutters
      className={styles.imageRequest}
      sx={{ opacity: request.completed ? '50%' : null }}
      expanded={expanded}
      onChange={(_, expanded) => setExpanded(expanded)}
    >
      <AccordionSummary className={styles.summary}>
        <div className={styles.imageRequestRow} style={{ width: '100%' }}>
          <ImageRequestCompleteButton completed={request.completed} requestNumber={index + 1} toggleRequestComplete={toggleComplete} />
          <FormControl required error={hasImageTypeError} className={styles.imageRequestType} onClick={e => e.stopPropagation()}>
            {hasImageTypeError && <FormLabel error={hasImageTypeError}>Välja en bildtyp</FormLabel>}
            <Select
              required
              value={request.imageType ?? ''}
              label="Bildtyp"
              onChange={e => updateRequest({ imageType: e.target.value as ImageType })}
            >
              {CaseImageRequestImageTypes.map(t => (
                <MenuItem key={t} value={t}>
                  {ImageTypeConverter.ToName(t)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div className={styles.imageRequestRowSpacer} />
          <ImageRequestAspectStatusText name={'Stående'} checked={request.takePortrait} extraText={request.portraitDimensions} />
          <ImageRequestAspectStatusText name={'Liggande'} checked={request.takeLandscape} extraText={request.landscapeDimensions} />
          <ImageRequestAspectStatusText name={'Kvadratisk'} checked={request.takeSquare} extraText={request.squareDimensions} />
          <ImageRequestAspectStatusText name={'Modell'} checked={request.hasModel} />
          <ImageRequestAspectStatusText name={'Husdjur'} checked={request.hasPet} />
          <ImageRequestAspectStatusText name={'TV'} checked={request.forTv} />
          <IconButton
            title="Expandera"
            onClick={e => {
              e.stopPropagation();
              setExpanded(!expanded);
            }}
          >
            {expanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </div>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <CaseTextField
          label="Bildkommentarer"
          multiline
          variant="outlined"
          value={request.comments}
          setValue={comments => updateRequest({ comments })}
          className={styles.imageRequestComments}
        />

        <Stack direction="row" flexWrap="wrap">
          <div className={styles.imageRequestRow}>
            <CaseImageRequestDimension
              name="Stående"
              checked={portraitValues.checked}
              dimensions={portraitValues.dimensions}
              isReadonly={portraitValues.isReadonly}
              onChange={(checked, dimensions) => updateRequest({ takePortrait: checked, portraitDimensions: dimensions })}
            />
            { showLandscapeDimensions && (<CaseImageRequestDimension
              name="Liggande"
              checked={request.takeLandscape}
              dimensions={request.landscapeDimensions}
              onChange={(checked, dimensions) => updateRequest({ takeLandscape: checked, landscapeDimensions: dimensions })}
            />)}
            { showSquareDimensions && (<CaseImageRequestDimension
              name="Kvadratisk"
              checked={request.takeSquare}
              dimensions={request.squareDimensions}
              onChange={(checked, dimensions) => updateRequest({ takeSquare: checked, squareDimensions: dimensions })}
            />)}
          </div>
          <div className={styles.imageRequestRowSpacer} />
          <Stack direction="row" flexWrap="nowrap" justifyContent="space-between">
            <div>
              <ImageRequestCheckBox name={'Modell'} checked={request.hasModel} onChange={checked => updateRequest({ hasModel: checked })} />
              <ImageRequestCheckBox name={'Husdjur'} checked={request.hasPet} onChange={checked => updateRequest({ hasPet: checked })} />
              <ImageRequestCheckBox name={'TV'} checked={request.forTv} onChange={checked => updateRequest({ forTv: checked })} />
            </div>
            <IconButton title="Ta bort bild" onClick={deleteRequest}>
              <Delete />
            </IconButton>
          </Stack>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
}

export type Props = {
  caze: Case;
};

export function CaseImageRequestForm({ caze }: Props) {
  const dispatch = useAppDispatch();
  const addRequest = () => {
    dispatch(addCaseImageRequest({ caseId: caze.id }));
  };
  const updateImageRequest = (index: number, imageRequest: Partial<CaseImageRequest>) => {
    dispatch(updateCaseImageRequest({ index, imageRequest }));
  };
  const removeImageRequest = (index: number) => {
    dispatch(removeCaseImageRequest({ index }));
  };

  const imageRequests = caze.imageRequests ?? [];

  const hasPet = caze.imageRequests.some(c => c.hasPet);
  const hasModel = caze.imageRequests.some(c => c.hasModel);
  const forTv = caze.imageRequests.some(c => c.forTv);

  const sectionTitleIcons = (
    <>
      {hasModel && <EmojiPeople />}
      {hasPet && <Pets />}
      {forTv && <Tv />}
    </>
  );

  const onDragEnd = (result: DropResult, provided: ResponderProvided) => {
    // Don't do anything if dropped outside or on same position
    if (result.destination == null || result.source.index === result.destination.index) {
      return;
    }

    const oldIndex = result.source.index;
    const newIndex = result.destination.index;

    const newImageRequests = [...imageRequests];
    const [removed] = newImageRequests.splice(oldIndex, 1);
    newImageRequests.splice(newIndex, 0, removed);

    newImageRequests.forEach((r, i) => {
      updateImageRequest(i, r);
    });
  };

  const getImageRequests = () =>
    imageRequests.map((r, idx) => (
      <Draggable key={idx} draggableId={String(idx)} index={idx}>
        {provided => (
          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <CaseImageRequestEntryForm
              key={idx}
              index={idx}
              request={r}
              updateRequest={imageRequest => {
                updateImageRequest(idx, imageRequest);
              }}
              deleteRequest={() => removeImageRequest(idx)}
            />
          </div>
        )}
      </Draggable>
    ));

  return (
    <CaseFormSection caseId={caze.id} title={'Bildbeställning'} titleComponent={sectionTitleIcons}>
      <div className={styles.actionRow}>
        <Button onClick={addRequest}>Lägg till</Button>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <StrictModeDroppable droppableId="imageRequests" direction="vertical">
          {provided => (
            <div className={styles.imageRequests} ref={provided.innerRef} {...provided.droppableProps}>
              {getImageRequests()}
              {provided.placeholder}
            </div>
          )}
        </StrictModeDroppable>
      </DragDropContext>
    </CaseFormSection>
  );
}
