export enum CaseType {
  Unknown = 0,
  ContextualPhoto = 1,
  InstallationPhoto = 2,
  SingleProduct = 3,
  ProductCollection = 4,
  DiningSet = 5,
  Retouch = 6,
  Video = 7,
  ProductContextual = 8,
}

export class CaseTypes {
  public static AllExcept(...excludedTypes: CaseType[]): CaseType[] {
    return Object.values(CaseType)
      .map((val: any) => val as CaseType)
      .filter(val => !isNaN(val) && !excludedTypes.includes(val));
  }
}

export interface EnumMapping {
  slug: string;
  name: string;
}

export class CaseTypeConverter {
  public static readonly Mapping: Record<CaseType, EnumMapping> = {
    [CaseType.Unknown]: { slug: 'unknown', name: 'Okänd' },
    [CaseType.ContextualPhoto]: { slug: 'contextual-photo', name: 'Miljöbild' },
    [CaseType.InstallationPhoto]: { slug: 'installation-photo', name: 'Installationsbild' },
    [CaseType.SingleProduct]: { slug: 'single-product', name: 'Produktbild' },
    [CaseType.ProductCollection]: { slug: 'product-collection', name: 'Samlingsbild Fond' },
    [CaseType.DiningSet]: { slug: 'dining-set', name: 'Matgrupp' },
    [CaseType.Retouch]: { slug: 'retouch', name: 'Retusch' },
    [CaseType.Video]: { slug: 'video', name: 'Rörligt' },
    [CaseType.ProductContextual]: { slug: 'product-contextual', name: 'Produktbild miljö' },
  };

  public static ToSlug(type: CaseType): string {
    if (!(type in this.Mapping)) {
      type = CaseType.Unknown;
    }

    return this.Mapping[type].slug;
  }

  public static ToName(type: CaseType | undefined): string {
    if (!type || !(type in this.Mapping)) {
      type = CaseType.Unknown;
    }

    return this.Mapping[type].name;
  }

  public static ToType(slug: string | undefined): CaseType {
    if (slug === undefined) {
      return CaseType.Unknown;
    }

    // Each key in the mapping is represented as an object
    // property (e.g. { "0": {}, "1": {} }) under the hood.
    // Due to this, the key in the iteration below is of
    // string type. Additional casting of the key is
    // therefore required in order to ...
    for (let key in this.Mapping) {
      // ... look up the entry in the mapping.
      let type = Number(key) as CaseType;
      if (this.Mapping[type].slug === slug) {
        return type;
      }
    }

    return CaseType.Unknown;
  }
}

export enum ImageType {
  Unknown = 0,
  ContextualPhoto = 1,
  InstallationPhoto = 2,
  Product = 3,
  ProductCollection = 4,
  DiningSet = 5,
  ProductAlt = 6,
  Rendered = 7,
  Detail = 8,
  Internal = 9,
  ProductContextual = 10,
  Video = 11,
}

export class ImageTypes {
  public static All = () => ImageTypes.AllExcept([]);
  public static AllExcept(excludedTypes: ImageType[]): ImageType[] {
    return Object.values(ImageType)
      .map((val: any) => val as ImageType)
      .filter(val => !isNaN(val) && !excludedTypes.includes(val));
  }
}

export class ImageTypeConverter {
  public static readonly Mapping: Record<ImageType, string> = {
    [ImageType.Unknown]: 'Okänd',
    [ImageType.ContextualPhoto]: 'Miljöbild',
    [ImageType.InstallationPhoto]: 'Installationsbild',
    [ImageType.Product]: 'Produktbild',
    [ImageType.ProductAlt]: 'Alternativ produktbild',
    [ImageType.ProductCollection]: 'Samlingsbild Fond',
    [ImageType.DiningSet]: 'Matgrupp',
    [ImageType.Rendered]: '3D-bild Produkt',
    [ImageType.Detail]: 'Detaljbild',
    [ImageType.Internal]: 'Intern bild',
    [ImageType.ProductContextual]: 'Produktbild miljö',
    [ImageType.Video]: 'Rörligt',
  };

  public static ToName(type: ImageType | undefined): string {
    if (!type || !(type in this.Mapping)) {
      type = ImageType.Unknown;
    }

    return this.Mapping[type];
  }
}

// Used for product Cases in kanban
export enum LegacyDeliveryStatus {
  Ordered = 'Beställd',
  Available = 'I fotolager',
  Delivered = 'Levererad',
  Finished = 'Avslutad',
}

// used for articles in cases, unsure how much this feature is used by the users
export enum DeliveryStatus {
  Unspecified = 0,
  Ordered = 1,
  InStock = 2,
}

export class DeliveryStatusConverter {
  private static readonly Mapping: Record<DeliveryStatus, string> = {
    [DeliveryStatus.Unspecified]: '-',
    [DeliveryStatus.Ordered]: 'Beställd',
    [DeliveryStatus.InStock]: 'I fotolager',
  };

  public static ToName(type: DeliveryStatus | undefined): string | undefined {
    if (!type || !(type in this.Mapping)) {
      type = DeliveryStatus.Unspecified;
    }

    return this.Mapping[type];
  }
}

export enum ImageClassification {
  Unspecified = 0,
  NoRoom = 1,
  Bathroom = 2,
  KidsRoom = 3,
  Hallway = 4,
  Workplace = 5,
  DiningRoom = 6,
  Bedroom = 7,
  GardenRoom = 8,
  LivingRoom = 9,
}

export class ImageClassificationConverter {
  public static readonly Mapping: Record<ImageClassification, string> = {
    [ImageClassification.Unspecified]: 'Ospecifierad',
    [ImageClassification.NoRoom]: 'Inget rum',
    [ImageClassification.Bathroom]: 'Badrum',
    [ImageClassification.KidsRoom]: 'Barnrum',
    [ImageClassification.Hallway]: 'Hall',
    [ImageClassification.Workplace]: 'Kontor',
    [ImageClassification.DiningRoom]: 'Matplats',
    [ImageClassification.Bedroom]: 'Sovrum',
    [ImageClassification.GardenRoom]: 'Trädgård',
    [ImageClassification.LivingRoom]: 'Vardagsrum',
  };

  public static ToName(type: ImageClassification | undefined): string {
    if (!type || !(type in this.Mapping)) {
      type = ImageClassification.Unspecified;
    }

    return this.Mapping[type];
  }
}

export class ImageClassifications {
  public static All = () => ImageClassifications.AllExcept([]);
  public static AllExcept(excluded: ImageClassification[] | ImageClassification): ImageClassification[] {
    const excludedStates = Array.isArray(excluded) ? excluded : [excluded];
    return Object.values(ImageClassification)
      .map((val: any) => val as ImageClassification)
      .filter(val => !isNaN(val) && !excludedStates.includes(val));
  }
}

export enum ImageState {
  None = 0,
  New = 1,

  AwaitingProofing = 2,
  ProofingInProgress = 3,
  ProofedOk = 4,
  ProofedNotOk = 5,

  AwaitingRetouch = 6,
  RetouchInProgress = 7,
  RetouchProofingPending = 8,
  AwaitingRetouchEnrichment = 9,

  Approved = 64,
  ApprovedHold = 65,
  ApprovedTimer = 66,
  ApprovedInHouse = 67,

  Archived = 128,

  Deleted = 256,
}

export class ImageStateConverter {
  public static readonly Mapping: Record<ImageState, string> = {
    [ImageState.None]: 'Ospecifierad',
    [ImageState.New]: 'Ny bild',

    [ImageState.AwaitingProofing]: 'Inväntar korrektur',
    [ImageState.ProofingInProgress]: 'Under korrektur',
    [ImageState.ProofedOk]: 'Korrad OK',
    [ImageState.ProofedNotOk]: 'Korrad EJ OK',

    [ImageState.AwaitingRetouch]: 'Beställd retusch',
    [ImageState.RetouchInProgress]: 'Retuscheras',
    [ImageState.RetouchProofingPending]: 'Under retuschkorrektur',
    [ImageState.AwaitingRetouchEnrichment]: 'Inväntar retuschberikning',

    [ImageState.Approved]: 'Godkänd',
    [ImageState.ApprovedHold]: 'Godkänd Avvakta',
    [ImageState.ApprovedTimer]: 'Godkänd Timer',
    [ImageState.ApprovedInHouse]: 'Godkänd Inhouse',

    [ImageState.Archived]: 'Arkiverad',

    [ImageState.Deleted]: 'Borttagen',
  };

  public static ToName(type: ImageState | undefined): string {
    if (!type || !(type in this.Mapping)) {
      type = ImageState.None;
    }

    return this.Mapping[type];
  }
}

export class ImageStates {
  public static All = () => ImageStates.AllExcept([]);
  public static AllExcept(excluded: ImageState[] | ImageState): ImageState[] {
    const excludedStates = Array.isArray(excluded) ? excluded : [excluded];
    return Object.values(ImageState)
      .map((val: any) => val as ImageState)
      .filter(val => !isNaN(val) && !excludedStates.includes(val));
  }
}

export class CaseValidator {
  public static IsValid(caze: Case): boolean {
    let isValid = caze != null;

    if (isValid && CaseValidator.IsRetouchCase(caze)) {
      return CaseValidator.HasValidRetouchCaseProps(caze);
    }

    return isValid && CaseValidator.HasValidCaseProps(caze);
  }

  public static IsRetouchCase(caze: Case) {
    return caze.type === CaseType.Retouch;
  }

  public static HasValidRetouchCaseProps(caze: Case): boolean {
    const reference = (caze as RetouchCase).reference;
    return reference != null && CaseValidator.IsValidReference(reference);
  }

  public static HasValidCaseProps(caze: Case): boolean {
    let isValid = true;
    if (caze.imageRequests) isValid &&= caze.imageRequests.every(req => req.imageType);

    return isValid;
  }

  public static HasValue(str: string | undefined): boolean {
    return str != null && str.length > 0;
  }

  public static HaveValues(...strings: string[]): boolean {
    return strings.every(str => str !== null && str.length > 0);
  }

  public static IsValidReference(ref: Reference | undefined): boolean {
    return ref == null || ref.imagePointers != null || ref.caseId != null;
  }

  public static HasValidCombinationOf(deliveryStatus: string, estimatedDeliveryDate: string | undefined) {
    if (deliveryStatus == null || deliveryStatus.length === 0) {
      return false;
    }

    return (
      (deliveryStatus === LegacyDeliveryStatus.Ordered && estimatedDeliveryDate != null && estimatedDeliveryDate.length > 0) ||
      estimatedDeliveryDate == null
    );
  }

  public static HasValidArticles(articles: Article[]) {
    if (articles == null) {
      return false;
    }

    articles.forEach(a => {
      if (!CaseValidator.HaveValues(a.articleId, a.productName) || a.quantity < 1) {
        return false;
      }
    });
  }
}

export type Case =
  | ContextualPhotoCase
  | InstallationPhotoCase
  | SingleProductCase
  | ProductCollectionCase
  | ProductContextualCase
  | DiningSetCase
  | RetouchCase
  | VideoCase;

export interface ContextualPhotoCase extends PhotoCase {
  type: CaseType.ContextualPhoto;
}
export interface InstallationPhotoCase extends PhotoCase {
  type: CaseType.InstallationPhoto;
}

export interface VideoCase extends PhotoCase {
  type: CaseType.Video;
}
export interface PhotoCase extends CaseBase {
  brief: string;
  imageDimensions?: string;
}

export interface SingleProductCase extends CaseBase {
  type: CaseType.SingleProduct;
  primaryImageId: string;
}
export interface ProductCollectionCase extends CaseBase {
  type: CaseType.ProductCollection;
}

export interface ProductContextualCase extends CaseBase {
  type: CaseType.ProductContextual;
}

export interface DiningSetCase extends CaseBase {
  type: CaseType.DiningSet;
}

export interface RetouchCase extends CaseBase {
  type: CaseType.Retouch;
  reference?: Reference;
  inspiration?: Inspiration;
  newArticleId: string;
}

export enum StateEnum {
  /* Represents an unknown state */
  none = 0,

  /* Represents a case that is new */
  incoming = 0b1, // 1

  /* Represents a case that is ordered */
  ordered = 0b100000000000, // 2048

  /* Represents a case that is confirmed */
  confirmed = 0b10, // 2

  /* Represents a case that is planned */
  planned = 0b100, // 4

  /* Represents a case that is in progress */
  inProgress = 0b1000, // 8

  /* Represents a case that is being reviewed */
  reviewPending = 0b10000, // 16

  /* Represents a case that is awaiting retouch */
  awaitingRetouch = 0b100000, // 32

  /* Represents a case that is being retouched */
  retouchInProgress = 0b1000000, // 64

  /* Represents a case where retouch is being reviewed */
  retouchReviewPending = 0b10000000, // 128

  /* Represents a case that is closed */
  closed = 0b1000000000, // 512

  /* Represents a case that is deleted */
  deleted = 0b10000000000, // 1024
}

export class States {
  public static All = () => States.AllExcept([]);
  public static AllExcept(excludedStates: StateEnum[]): StateEnum[] {
    return Object.values(StateEnum)
      .map((val: any) => val as StateEnum)
      .filter(val => !isNaN(val) && !excludedStates.includes(val));
  }
}

export class CaseStateConverter {
  public static ToText(state: State): string {
    switch (state.state) {
      case StateEnum.incoming:
        return 'Beställer';
      case StateEnum.ordered:
        return 'Beställd';
      case StateEnum.confirmed:
        return 'Bekräftad';
      case StateEnum.planned:
        return 'Planerad';
      case StateEnum.inProgress:
        return 'Skapas';
      case StateEnum.reviewPending:
        return 'Granskas';
      case StateEnum.awaitingRetouch:
        return 'Inväntar retusch';
      case StateEnum.retouchInProgress:
        return 'Retuscheras';
      case StateEnum.retouchReviewPending:
        return 'Retusch granskas';
      case StateEnum.closed:
        return 'Avslutad';
      case StateEnum.deleted:
        return 'Borttagen';
      default:
        return '?';
    }
  }
}

export enum CaseRelationKind {
  /** Unknown relationship */
  none = 0,

  /** The case is a parent of the related case */
  parent = 1,

  /** The case is a child of the related case */
  child = 2,

  /** The case is a part of the related project case */
  project = 3,

  /** The case is linked by the related case */
  linkedBy = 4,

  /** The case is linking to the related case */
  linkedTo = 5,
}

export interface State {
  state: StateEnum;
}

export interface Article {
  id?: string;
  articleId: string;
  productName: string;
  productDescription: string;
  quantity: number;
  deliveryStatus?: DeliveryStatus;
}

export interface SpecificationCollection {
  id: string;
  items: Specification[];
}

export interface Specification {
  id: string;
  value: string;
}

export interface SystemTag {
  id: string;
  color: string;
  name: string;
  description: string;
}

export interface UserTag {
  id: string;
  name: string;
  email: string;
}

export interface ProjectTag {
  id: string;
  name: string;
  description: string;
  color: string;
  activeFrom?: string;
  activeTo?: string;
}

export type ColoredTag = SystemTag | ProjectTag;

export interface PaginatedResponse<T> {
  count: number;
  items: T[];
  next: string;
}

export interface GenericSupplierDesign {
  supplier?: string;
  design?: string;
}

export interface Tiling extends GenericSupplierDesign {}
export interface Floor extends GenericSupplierDesign {}

export interface Wallpaper extends GenericSupplierDesign {}

export interface Paint {
  supplier?: string;
  color?: string;
}

export interface Image {
  type: ImageType;
  id: string;
  parentId: string;
  lookupEntry: number;
  eTag: string;
  projectTag?: ProjectTag;
  systemTags: SystemTag[];
  activeVersion: string;
  versions: Record<string, ImageVersion>;

  productName?: string;
  articleId?: string;
  productDescription?: string;
  activeFrom?: string;
  activeTo?: string;
  photographer?: string;
  stylist?: string;
  retouchChanges?: string;
  retouchedBy?: string;

  classification?: ImageClassification;
  state: ImageState;

  additionalArticles: Article[] | null;
  specifications: SpecificationCollection[] | null;
  wallpaper?: Wallpaper;
  paint?: Paint;
  tiling?: Tiling;
  floor?: Floor;

  pimAssociatedArticles: string[];

  publicationDate?: string;
  photoAndRetouchDeadline?: string;

  created: string;
  createdById?: string;
  modified: string;
  modifiedById?: string;
}

export type ImageDates = 'activeFrom' | 'activeTo' | 'publicationDate' | 'photoAndRetouchDeadline';

export interface ImagePointer {
  imageId: string;
  imageVersion: string;
}

export interface ImageVersion extends DerivedImageHolder {
  id: string;
  createdAt: string;
  fileName: string;
  uri: string;
  origin: ImageOrigin;
}

export type ImageOriginMetadataLabelType = Omit<ImageOriginSystemMetadata, 'muiVariant'>;

interface ImageOriginSystemMetadata {
  long: string;
  short: string;
  name: string;
  muiVariant: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
}

export const ImageOriginMetaData: Record<ImageOriginSystem, ImageOriginSystemMetadata> = {
  '3DPortal': {
    long: '3D-portal: Hämtad bild',
    short: '3D',
    name: '3D-portal',
    muiVariant: 'warning',
  },
  Case: {
    long: 'Mediaportalen: Beställd och fotograferad',
    short: 'M',
    name: 'Mediaportalen',
    muiVariant: 'primary',
  },
  PIM: {
    long: 'PIM: Migrerad bild',
    short: 'P',
    name: 'PIM',
    muiVariant: 'success',
  },
  Mediabank: {
    long: 'Mediaportalen: Direktuppladdning',
    short: 'M',
    name: 'Mediabanken',
    muiVariant: 'secondary',
  },
};

export interface ImageOrigin {
  originSystem: ImageOriginSystem;
  originIdentifier: string;
}

export type ImageOriginSystem = 'Case' | 'PIM' | '3DPortal' | 'Mediabank';

export interface DerivedImage {
  uri: string;
  format: string;
  dimensionUnit: string;
  width: number;
  height: number;
}

export enum AttachmentCategory {
  SupplementaryInformation = 0,
  OriginalImage = 1,
}

export class AttachmentCategoryConverter {
  public static readonly Mapping: Record<AttachmentCategory, EnumMapping> = {
    [AttachmentCategory.SupplementaryInformation]: { slug: 'attachment', name: 'Bilaga' },
    [AttachmentCategory.OriginalImage]: { slug: 'original', name: 'Original' },
  };

  public static ToSlug(category: AttachmentCategory): string {
    if (!(category in this.Mapping)) {
      category = AttachmentCategory.SupplementaryInformation;
    }

    return this.Mapping[category]?.slug;
  }

  public static ToText(category?: AttachmentCategory): string {
    if (!category || !(category in this.Mapping)) {
      category = AttachmentCategory.SupplementaryInformation;
    }

    return this.Mapping[category]?.name;
  }
}

export interface DerivedImageHolder {
  derivedImages: DerivedImage[];
}

export interface Attachment extends DerivedImageHolder {
  id: string;
  uri: string;
  title: string;
  mediaType: string;
  category: AttachmentCategory;
  size: number;
  created?: string;
}

export interface CaseRelation {
  caseId: string;
  caseType?: CaseType;
  productName?: string;
  caseRelationKind: CaseRelationKind;
}

export interface Comment {
  id: string;
  authorUserId: string;
  authorEmail: string;
  content: string;
  timestamp: string;
}

export interface Reference {
  caseId?: string;
  imagePointers: ImagePointer[];
}

export interface Inspiration {
  caseId?: string;
  imagePointers: ImagePointer[];
}

export interface CaseImageRequest {
  completed: boolean;
  imageType: ImageType;
  hasModel: boolean;
  hasPet: boolean;
  forTv: boolean;
  comments: string;

  takePortrait: boolean;
  portraitDimensions?: string;

  takeLandscape: boolean;
  landscapeDimensions?: string;

  takeSquare: boolean;
  squareDimensions?: string;
}

export function DefaultCaseImageRequest(): CaseImageRequest {
  return {
    completed: false,
    imageType: 0,
    hasModel: false,
    hasPet: false,
    forTv: false,
    comments: '',

    takePortrait: false,
    portraitDimensions: undefined,
    takeLandscape: false,
    landscapeDimensions: undefined,
    takeSquare: false,
    squareDimensions: undefined,
  };
}

export interface CaseVideoRequestFormat {
  landscape: boolean;
  portrait: boolean;
}

export interface CaseVideoRequestChannel {
  socialMedia: boolean;
  banners: boolean;
  youTube: boolean;
  mioWebsite: boolean;
  storeTv: boolean;
}

export interface CaseVideoRequestType {
  campaign: boolean;
  product: boolean;
  inspiration: boolean;
  function: boolean;
}

export interface CaseVideoRequestDuration {
  seconds6: boolean;
  seconds15: boolean;
  seconds30: boolean;
  over1Minute: boolean;
}

export interface CaseVideoRequest {
  description: string;

  formats: CaseVideoRequestFormat;
  channels: CaseVideoRequestChannel;
  types: CaseVideoRequestType;
  durations: CaseVideoRequestDuration;
}

export const DefaultCaseVideoRequest: CaseVideoRequest = {
  description: '',

  formats: { landscape: false, portrait: false },

  channels: {
    socialMedia: false,
    banners: false,
    youTube: false,
    mioWebsite: false,
    storeTv: false,
  },
  types: {
    campaign: false,
    product: false,
    inspiration: false,
    function: false,
  },
  durations: {
    seconds6: false,
    seconds15: false,
    seconds30: false,
    over1Minute: false,
  },
};

export interface SearchCaseBase extends CaseBase {
  retouchedByFullName?: string;
}

export interface CaseBase extends CaseEnrichmentHolder {
  id: string;
  ownerFullName: string;
  ownerEmail: string;
  plannerFullName?: string;
  plannerEmail?: string;
  etag?: string;
  lookupEntry?: number;
  type: CaseType;
  title: string;
  description: string;
  deliveryStatus: LegacyDeliveryStatus;
  priority: number;

  created: string;
  createdByFullName?: string;
  modified: string;
  modifiedByFullName?: string;
  plannedStart?: string;
  plannedEnd?: string;
  estimatedDeliveryDate?: string;

  state: State;
  systemTags: SystemTag[];
  userTags: UserTag[];
  projectTag?: ProjectTag;
  imagePointers: ImagePointer[];
  videoPointers: ImagePointer[];
  attachments: Attachment[];
  caseRelations: CaseRelation[];
  comments: Comment[];
  imageRequests: CaseImageRequest[];
  videoRequest?: CaseVideoRequest;
}

export interface CaseEnrichmentHolder {
  imageActiveFrom?: string;
  imageActiveTo?: string;
  photographer?: string;
  stylist?: string;
  retouchChanges?: string;
  retouchedBy?: string;

  imageClassification?: ImageClassification;
  imageState?: ImageState;

  articles: Article[] | null;
  wallpaper?: Wallpaper;
  paint?: Paint;
  tiling?: Tiling;
  floor?: Floor;

  approvalDeadline?: string;
  photoAndRetouchDeadline?: string;
}

export function GetCaseName(caze: CaseBase) {
  return `${caze.title} - ${caze.lookupEntry}`;
}

export function GetProductNameForCase(caze: CaseEnrichmentHolder) {
  return caze.articles?.[0]?.productName;
}

export interface SearchResult<T> {
  count: number;
  items: T[];
  next: string;
}

export interface CaseReference {
  item: Case;
}

export function caseCtor(type: number): Case {
  return {
    type,

    title: '',

    id: '',
    ownerFullName: '',
    ownerEmail: '',
    plannerEmail: '',
    plannerFullName: '',
    description: '',
    deliveryStatus: LegacyDeliveryStatus.Ordered,
    /*
     * Priority cannot be set via upsert, the timestamp from
     * created will be used until an action that sets priority
     * is performed in e.g. kanban.
     * */
    priority: 0,

    created: '',
    modified: '',
    estimatedDeliveryDate: undefined,
    approvalDeadline: undefined,
    photoAndRetouchDeadline: undefined,

    state: { state: StateEnum.incoming },
    imageClassification: ImageClassification.Unspecified,
    articles: [],
    systemTags: [],
    userTags: [],
    imagePointers: [],
    videoPointers: [],
    attachments: [],
    caseRelations: [],
    comments: [],
    imageRequests: [],

    brief: '',

    reference: undefined,
    newArticleId: '',
  };
}

export function CreateNewSystemTag(): SystemTag {
  return {
    id: '',
    description: '',
    color: '',
    name: 'Ny Tagg',
  };
}

export function CreateNewUserTag(): UserTag {
  return {
    id: '',
    name: 'Ny Användare',
    email: '',
  };
}

export function CreateNewProjectTag(): ProjectTag {
  return {
    id: '',
    name: 'Nytt Projekt',
    description: '',
    color: '',
  };
}

export interface EnumerationItem {
  key: string;
  value: string;
}

export interface EnumerationUserItem {
  key: string;
  value: string;
  userId: string;
}

export interface Enumerations {
  projectNames: EnumerationItem[];
  wallPaperSuppliers: EnumerationItem[];
  wallColorSuppliers: EnumerationItem[];
  photographers: EnumerationUserItem[];
  stylists: EnumerationItem[];

  editors: EnumerationUserItem[];

  tilingSuppliers: EnumerationItem[];
  floorSuppliers: EnumerationItem[];
}

export const lookupEnum = (enums: EnumerationItem[] | undefined, key?: string) => {
  const enumEntry = enums?.find(e => e.key === key);

  if (enumEntry != null) {
    return enumEntry.value;
  }

  // TODO: temporary workaround while we rework common value selections
  if (isNaN(Number(key))) {
    return key;
  }

  return;
};

export interface CaseUserAssignment {
  userId: string;
}
